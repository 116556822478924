<template>
  <v-row>
    <v-col cols="12" md="7">
      <v-card>
        <v-card-title>Mi Carrito</v-card-title>
        <v-card-text>
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th
                    class="p-0"
                    style="width: 50px"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  ></th>
                  <th class="p-0"></th>
                  <th class="p-0" style="min-width: 180px"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in cart.lines">
                  <tr v-bind:key="i">
                    <td class="pl-0 py-4" v-if="$vuetify.breakpoint.mdAndUp">
                      <div class="symbol symbol-50 symbol-light mr-1">
                        <span class="symbol-label">
                          <img
                            :src="`${item.product.image.mediaLink}/350`"
                            class="h-50 align-self-center"
                            alt=""
                          />
                        </span>
                      </div>
                    </td>
                    <td class="pl-0">
                      <a
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ item.product.title }}
                      </a>
                      <div>
                        <a
                          class="text-muted font-weight-bold text-hover-primary"
                        >
                          {{ item.product.description }}
                        </a>
                      </div>
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.product.euros.toFixed(2) }}€
                      </span>
                      <span class="text-muted font-weight-bold">
                        <QuantitySelector :line="item"></QuantitySelector>
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
          <div class="d-flex align-items-center justify-content-between mb-4">
            <span class="font-weight-bold font-size-sm mr-2">Total</span>
            <span class="font-weight-bolder text-right"
              >{{ cart.total.toFixed(2) }}€</span
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>Mi información</v-card-title>
        <v-card-text class="text--primary">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group
              id="input-group-1"
              label="Nombre completo:"
              label-for="input-1"
              class="mb-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.fullName"
                required
                placeholder="Escribe tu nombre completo"
              ></b-form-input>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-group-2"
                  label="Correo electrónico:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.email"
                    type="email"
                    required
                    placeholder="Escribe tu correo electrónico"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6 pl-0">
                <b-form-group
                  id="input-group-3"
                  label="Teléfono de contacto:"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="form.phone"
                    required
                    placeholder="Escribe tu teléfono"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div v-if="this.business.pickUpFrom">
            <v-card-title class="p-0 text-dark">Envío a casa (opcional)</v-card-title>
            <div class="row">
              <div class="col-md-6 pb-0">
                <b-form-group
                  id="input-group-4"
                  label="Dirección:"
                  label-for="input-4"
                  class="mb-1"
                >
                  <b-form-input
                    id="input-4"
                    v-model="form.direction"
                    placeholder="Escribe tu dirección"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 pl-0 pb-0">
                <b-form-group
                  id="input-group-5"
                  label="Piso y puerta:"
                  label-for="input-5"
                  class="mb-1"
                >
                  <b-form-input
                    id="input-5"
                    v-model="form.details"
                    placeholder="Escribe tu piso y puerta"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-group-5"
                  label="Código postal:"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    v-model="form.postalCode"
                    placeholder="Escribe tu código postal"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 pl-0">
                <b-form-group
                  id="input-group-6"
                  label="Ciudad:"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    v-model="form.city"
                    placeholder="Escribe el nombre de tu ciudad"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            </div>
            

            <div class="mb-2">
              <h6>Confirma la cesión de tus datos, según la GDPR:</h6>
            </div>
            <b-form-group>
              <b-form-checkbox
                id="conditions"
                v-model="conditions"
                name="conditions"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Acepto ceder mis datos personales al punto de venta y a NAOS para beneficiarme del servicio de envío a domicilio.
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="privacy"
                v-model="privacy"
                name="privacy"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Acepto la Política de Uso de
                <a
                  href="https://www.bioderma.es/condiciones-de-uso-de-la-web"
                  target="_blank"
                  >Bioderma</a
                >
                <span> y </span>
                <a
                  href="https://www.esthederm.com/es/politica-de-uso"
                  target="_blank"
                  >Esthederm</a
                >
                y la Política de Privacidad de
                <a
                  href="https://www.bioderma.es/informacion-adicional-sobre-proteccion-de-datos-bioderma-2a-capa"
                  target="_blank"
                  >Bioderma</a
                >
                <span> y </span>
                <a
                  href="https://www.esthederm.com/es/politica-de-privacidad"
                  target="_blank"
                  >Esthederm</a
                >
              </b-form-checkbox>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              v-if="cart.lines.length > 0"
              :disabled="conditions !== 'accepted' || privacy !== 'accepted'"
              >Realizar pedido</b-button
            >
          </b-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { RESET } from "@/core/services/store/cart.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import QuantitySelector from "../content/widgets/QuantitySelector";
import ApiService from "../../core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "cart",
  data() {
    return {
      form: {
        email: "",
        fullName: "",
        phone: "",
        direction: "",
        details: "",
        postalCode: "",
        city: "",
      },
      show: true,
      conditions: "not_accepted",
      privacy: "not_accepted",
    };
  },
  components: {
    QuantitySelector,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tienda" }]);
  },
  methods: {
    resetForm() {
      // Reset our form values
      this.form.email = "";
      this.form.fullName = "";
      this.form.phone = "";
      this.form.direction = "";
      this.form.details = "";
      this.form.postalCode = "";
      this.form.city = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      ApiService.post("order", {
        ...this.form,
        code: this.business.code,
        lines: this.cart.lines,
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Pedido realizado correctamente.",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.resetForm();
            this.$store.dispatch(RESET);
            this.$router.push(`/${this.business.code}`);
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "No se ha podido realizar el pedido. Revise los datos o pruebe más tarde.",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.resetForm();
    },
  },
  computed: {
    ...mapGetters(["cart", "business"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
